import { j as e } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as r } from "./chunks/clsx.0d472180.js";
import { b as c } from "./chunks/Tabs.dbeb90fe.js";
import { u as p } from "./chunks/TabsContext.9430d3b8.js";
import { A as m } from "./chunks/index.29668fd9.js";
import { m as u } from "./chunks/motion.24089995.js";
import "react";
import "./chunks/useListState.980882c9.js";
import "./chunks/utils.f8c6b750.js";
import "react-dom";
const a = ({ className: t, children: o, disabled: s, icon: b, ...i }) => {
  const { id: n } = p();
  return /* @__PURE__ */ e.jsx(
    c,
    {
      ...i,
      isDisabled: s,
      className: r(
        t,
        "relative z-10 flex min-h-6 cursor-pointer snap-start items-center gap-0.75 text-nowrap rounded-xs px-2 py-1.5 text-s+ text-black outline-none transition hover:text-grey-600 focus-visible:ring md:px-3",
        // Selected
        "selected:cursor-default selected:text-blue selected:hover:bg-transparent",
        // Disabled
        "disabled:cursor-not-allowed disabled:text-grey-400 selected:disabled:text-blue-400"
      ),
      children: ({ isSelected: l, isDisabled: d }) => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
        o,
        /* @__PURE__ */ e.jsx(m, { children: l && /* @__PURE__ */ e.jsx(
          u.span,
          {
            layoutId: `${n}-indicator`,
            className: r(
              "absolute inset-x-0 bottom-0 h-[2px] origin-center rounded-full bg-blue",
              d && "bg-blue-400"
            )
          }
        ) })
      ] })
    }
  );
};
try {
  a.displayName = "Tab", a.__docgenInfo = { description: "", displayName: "Tab", props: { disabled: { defaultValue: null, description: "", name: "disabled", required: !1, type: { name: "boolean" } }, icon: { defaultValue: null, description: "", name: "icon", required: !1, type: { name: "IconMapMember" } } } };
} catch {
}
export {
  a as Tab
};

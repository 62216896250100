import cx from 'classnames';
import { Icon, Modal } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'components/common/IconButton';
import { MarketSwitcherBody } from './MarketSwitcherBody';
import { MarketSwitcherStep, useModalShown, useStep } from './hooks/useMarketSwitcherStore';
import { useMarketSwitcher } from './hooks/useMarketSwitcher';
import styles from './MarketSwitcherModal.module.scss';

const MarketSwitcherModal = () => {
  const { t } = useTranslation();
  const { initMarketSelection, toggleModalShown } = useMarketSwitcher();
  const modalShown = useModalShown();
  const step = useStep();

  return (
    <Modal
      boxClassName={styles.root}
      contentClassName={styles.content}
      open={modalShown}
      onClose={() => toggleModalShown(false)}
      size="m"
      closeButtonClassName={styles.closeButton}
    >
      {step === MarketSwitcherStep.LanguageSelection && (
        <IconButton
          type="button"
          className={cx(styles.action, styles.backButton)}
          onClick={initMarketSelection}
          aria-label={t('general.backAction')}
          icon="arrow-left"
        >
          <Icon name="arrow-left" size="m" />
        </IconButton>
      )}

      <MarketSwitcherBody />
    </Modal>
  );
};

export { MarketSwitcherModal };

import { AnchorOrButton, Icon, Text } from '@carvertical/ui';
import { useMarket } from 'hooks/useMarket';
import Flag from 'components/common/Flag';
import { useMarketSwitcher } from './hooks/useMarketSwitcher';
import type { SwitcherMarket } from './hooks/useMarketSwitcherStore';
import styles from './MarketSelectionButton.module.scss';

type MarketSelectionButtonProps = {
  market: SwitcherMarket;
};

const MarketSelectionButton = ({ market }: MarketSelectionButtonProps) => {
  const { getMarketLanguages } = useMarket();
  const { selectMarket } = useMarketSwitcher();
  const { countryCode, name } = market;
  const [mainLanguage, ...otherLanguages] = getMarketLanguages(countryCode);
  const multilingual = !!otherLanguages.length;

  const selectMultilingualMarket = () => {
    if (!multilingual) {
      return;
    }

    selectMarket(market);
  };

  return (
    <AnchorOrButton
      className={styles.root}
      href={!multilingual ? mainLanguage.slug : undefined}
      onClick={selectMultilingualMarket}
    >
      <Flag countryCode={countryCode} rounded as={undefined} className={undefined} size={24} />

      <Text as="span" variant="m+">
        {name}
      </Text>

      {multilingual && <Icon name="arrow-right" />}
    </AnchorOrButton>
  );
};

export { MarketSelectionButton };
export type { MarketSelectionButtonProps };

import { useTranslation } from 'next-i18next';
import { Button, Stack, Text } from '@carvertical/ui';
import { useSelectedMarket } from './hooks/useMarketSwitcherStore';
import { MarketSwitcherStepBase } from './MarketSwitcherStepBase';
import styles from './LanguageSelectionStep.module.scss';

const LanguageSelectionStep = () => {
  const { t } = useTranslation();
  const selectedMarket = useSelectedMarket();

  return (
    <MarketSwitcherStepBase title={t('marketSwitcher.languageTitle')}>
      {selectedMarket ? (
        <Stack
          className={styles.wrapper}
          mainAxisAlign={{ mobileUp: 'stretch', tabletPortraitUp: 'center' }}
          gap={{ mobileUp: 2, tabletPortraitUp: 3 }}
          type={{ mobileUp: 'vertical', tabletPortraitUp: 'horizontal' }}
        >
          {selectedMarket.languages.map(({ label, slug }) => (
            <Button className={styles.button} href={slug} key={slug} size="m" variant="outlined">
              {label}
            </Button>
          ))}
        </Stack>
      ) : (
        <Text>{t('general.noResultsLabel')}</Text>
      )}
    </MarketSwitcherStepBase>
  );
};

export { LanguageSelectionStep };

import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { Icon, Stack, Text } from '@carvertical/ui';
import styles from './MarketSearchInput.module.scss';

type MarketSearchInputProps = {
  value: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const MarketSearchInput = ({ className, onChange, value }: MarketSearchInputProps) => {
  const { t } = useTranslation();

  return (
    <Stack as="label" className={cx(styles.root, className)} gap={1} type="horizontal">
      {!value && (
        <Stack
          as="span"
          className={styles.placeholder}
          type="horizontal"
          crossAxisAlign="center"
          gap={1}
        >
          <Icon name="magnifying-glass" size="s" className={styles.icon} />

          <Text as="span" variant="xs">
            {t('marketSwitcher.countrySearchPlaceholder')}
          </Text>
        </Stack>
      )}

      <input
        placeholder={t('general.typeToSearchLabel')}
        className={styles.input}
        onChange={onChange}
        type="text"
        value={value}
      />
    </Stack>
  );
};

export { MarketSearchInput };
export type { MarketSearchInputProps };

import PropTypes from 'prop-types';
import { isPlainObject, startsWith, toLower } from 'lodash';
import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Stack, Text } from '@carvertical/ui';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { createInstance } from 'services/store';
import { useDiscount } from 'context/DiscountProvider';
import { Link } from 'components/common/Link';
import { LogoImage } from 'components/common/LogoImage';
import styles from './Logo.module.scss';

const PARTNER_LOGOS = {
  vinanalytics: 'vinanalytics.svg',
  gliapps: 'gliapps.svg',
  DCB_2019: 'dailycarblog.png',
  automanijak: 'automanijak.svg',
  m1a2r39nit91ol7im: 'autoride.png',
  mojvolan: 'moj-volan.png',
  vinformer: 'vinformer.png',
  47590335: 'carhelp.png',
  vindecodervehicle: 'vindecodervehicle.png',
  decodethis001: 'decode-this.png',
  radon3: {
    verificare: 'verificare-vin.png',
    historicocarro: 'historico-carro.svg',
    istorijevozila: 'istorije-vozila.svg',
    autonhistoria: 'auton-historia.svg',
    vindecoderpro: 'vin-decoder-pro.svg',
    verifiervoiture: 'verifier-voiture.svg',
    consultarvehiculo: 'consultar-vehiculo.svg',
    car30: 'istorije-vozila-new.svg',
    get20: 'bestautocheck.svg',
    vozila: 'istorije-vozila-new.svg',
  },
  CARVIN: 'carvin.svg',
  sprawdzanievin: 'sprawdzanievin.svg',
  DISCOUNTCHECKRO: 'verificareistoricauto.png',
  alinfrancisc: 'pickwell.png',
  REDUCERE20: 'aboutcars.png',
  AUTOVIN: 'autovin.png',
  OLXRO: 'autovit.svg',
  '665089d276120': 'autovit.svg',
  automoto: 'automoto.png',
  channel: {
    autovit: 'autovit.svg',
    olx: 'olx.svg',
  },
};
const STORAGE_KEY = 'inCooperationWith';

const store = createInstance('session');

const Logo = ({ className, to, suffix, priority, width = 180 }) => {
  const { t } = useTranslation();
  const [affiliate, setAffiliate] = useState(store.get(STORAGE_KEY));
  const { query, isReady } = useRouter();
  const [channel, setChannel] = useState();
  const { voucher } = useDiscount();

  useEffect(() => {
    const affiliate = query.a;
    if (!affiliate) {
      return;
    }
    store.set(STORAGE_KEY, affiliate);
    setAffiliate(affiliate);
  }, [query.a]);

  useEffect(() => {
    const channel = query.chan;
    if (!channel) {
      return;
    }
    if (startsWith(query.chan, 'aut_vin_')) {
      setChannel('autovit');
    } else if (startsWith(query.chan, 'olx_vin_')) {
      setChannel('olx');
    }
  }, [query.chan]);

  const logoFileName = (() => {
    if (!isReady) {
      return null;
    }

    const logoFileName =
      PARTNER_LOGOS.channel?.[channel] ||
      PARTNER_LOGOS[voucher] ||
      PARTNER_LOGOS[affiliate]?.[toLower(voucher)] ||
      PARTNER_LOGOS[affiliate];

    if (isPlainObject(logoFileName)) {
      return null;
    }

    return logoFileName;
  })();

  const renderLogo = () => (
    <>
      <div className={styles.image}>
        <LogoImage width={width} priority={priority} />
      </div>

      {!logoFileName && (
        <div
          className={classnames(
            styles.suffix,
            suffix && suffix.length >= 25 && styles.suffixCentered,
          )}
        >
          {suffix}
        </div>
      )}
    </>
  );

  return (
    <div className={classnames(styles.root, className)}>
      {to ? (
        <Link className={styles.link} to={to}>
          {renderLogo()}
        </Link>
      ) : (
        renderLogo()
      )}

      {logoFileName && (
        <Stack gap={0.5} type="horizontal" className={styles.partner} crossAxisAlign="center">
          <Text variant="xxsStrong" className={styles.partnerLabel}>
            {t('general.cooperationLabel')}
          </Text>

          <img
            className={classnames(
              styles.partnerLogo,
              styles[`partner_${channel}`],
              styles[`partner_${affiliate}`],
              styles[`partner_${voucher}`],
            )}
            src={`/images/logos/partners/${logoFileName}`}
            alt={affiliate}
          />
        </Stack>
      )}
    </div>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  suffix: PropTypes.string,
  priority: PropTypes.bool,
};
Logo.defaultProps = {
  className: undefined,
  to: '/',
  suffix: undefined,
  priority: false,
};

export default Logo;

import { Text, SkeletonWrapper } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { UserType } from 'types/user';
import withContainers from 'hoc/withContainers';
import UserContainer from 'containers/UserContainer';

type UserHeaderProps = {
  containers: {
    user: UserContainer;
  };
};

const UserHeader = ({ containers: { user } }: UserHeaderProps) => {
  const { t } = useTranslation();

  const { loading } = user?.state || {};
  const { userType, email, username = '' } = user?.details || {};

  const isIndividual = userType === UserType.Individual;
  const accountName = username && username !== email ? username : email || 'account';

  return (
    <SkeletonWrapper loading={loading} radius="l">
      {accountName && (
        <Text as="h1" variant="m" className="truncate font-semibold">
          {accountName}
        </Text>
      )}

      <Text variant="s+" align="left" textColor="darkSecondary">
        {isIndividual ? t('user.individualLabel') : t('user.legalEntityLabel')}
      </Text>
    </SkeletonWrapper>
  );
};

const WrappedUserHeader = withContainers({ user: UserContainer })(UserHeader);

export { WrappedUserHeader as UserHeader };
export type { UserHeaderProps };

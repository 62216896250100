import { isEmpty } from 'lodash';
import { useTranslation } from 'next-i18next';
import { Grid, Stack, Text } from '@carvertical/ui';
import { motion } from 'framer-motion';

import cx from 'classnames';
import { MarketSelectionButton } from './MarketSelectionButton';
import type { SwitcherMarket } from './hooks/useMarketSwitcherStore';
import styles from './MarketSelectionList.module.scss';

type MarketSelectionListProps = {
  markets: SwitcherMarket[];
};

const MarketSelectionList = ({ markets }: MarketSelectionListProps) => {
  const { t } = useTranslation();
  const marketsAvailable = !isEmpty(markets);

  return (
    <Grid
      as="ul"
      className={cx(styles.root, 'unstyledList')}
      columnGap={5}
      columnCount={
        marketsAvailable
          ? {
              mobileUp: 1,
              tabletPortraitUp: 2,
              tabletLandscapeUp: 3,
            }
          : 1
      }
      rowGap={{ mobileUp: 1.5, tabletPortraitUp: 1 }}
    >
      {marketsAvailable ? (
        markets.map((market) => (
          <Stack
            key={market.countryCode}
            as={motion.li}
            crossAxisAlign="stretch"
            // @ts-expect-error TS(2322)
            layout
          >
            <MarketSelectionButton market={market} />
          </Stack>
        ))
      ) : (
        <li>
          <Text>{t('general.noResultsLabel')}</Text>
        </li>
      )}
    </Grid>
  );
};

export { MarketSelectionList };
export type { MarketSelectionListProps };

import { api } from '../shared/api';
import { getAuthorizationHeader } from '../auth';

type QuotaResponse = {
  quotas: Record<string, number>;
};

const getUserQuotas = async (productId: number) => {
  const authHeader = getAuthorizationHeader();

  const { data } = await api.get<QuotaResponse>('/user/quotas', {
    headers: authHeader,
    data: { productId },
  });

  return data;
};

export { getUserQuotas };

import { j as a } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as o } from "./chunks/clsx.0d472180.js";
import { a as s } from "./chunks/Tabs.dbeb90fe.js";
import { m as i } from "./chunks/motion.24089995.js";
import "react";
import "./chunks/useListState.980882c9.js";
import "./chunks/utils.f8c6b750.js";
import "react-dom";
const r = ({ className: t, ...e }) => /* @__PURE__ */ a.jsx(i.div, { layoutScroll: !0, className: "relative -m-1 flex snap-x snap-mandatory overflow-x-auto p-1", children: /* @__PURE__ */ a.jsx(
  s,
  {
    ...e,
    className: o(
      t,
      "relative flex flex-1",
      // Bottom border
      'after:absolute after:-inset-x-0 after:bottom-0 after:block after:h-[2px] after:bg-grey-200 after:content-[""]'
    )
  }
) });
try {
  r.displayName = "TabList", r.__docgenInfo = { description: "", displayName: "TabList", props: {} };
} catch {
}
export {
  r as TabList
};

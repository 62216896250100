import { compact, map, orderBy } from 'lodash';
import { useMemo } from 'react';
import { useMarket } from 'hooks/useMarket';
import { getCountryName } from 'services/countries';
import { markets } from 'config-ts';
import {
  MarketSwitcherStep,
  type SwitcherMarket,
  useMarketSwitcherActions,
} from 'components/common/MarketSwitcher/hooks/useMarketSwitcherStore';
import type { OptionalMarketIdRecord } from 'types/market';

const ORDER: OptionalMarketIdRecord<number> = {
  europe: -1,
};
const IS_PRODUCTION = process.env.APP_ENV === 'production';

const useMarketSwitcher = () => {
  const { getMarketLanguages } = useMarket();
  const {
    changeStep,
    selectMarket: selectMarketAction,
    toggleModalShown: toggleModalShownAction,
  } = useMarketSwitcherActions();

  const initMarketSelection = () => changeStep(MarketSwitcherStep.MarketSelection);

  const toggleModalShown = (modalShown: boolean) => toggleModalShownAction(modalShown);

  const selectMarket = (market: SwitcherMarket) => selectMarketAction(market);

  const switcherMarkets = useMemo(() => {
    const availableMarkets = compact<SwitcherMarket>(
      map(
        markets,
        ({ countryCode, countryCodeLong, hidden, id }) =>
          (!IS_PRODUCTION || !hidden) && {
            countryCode,
            countryCodeLong,
            name: getCountryName(countryCode),
            languages: getMarketLanguages(countryCode),
            order: ORDER[id] || 0,
          },
      ),
    );

    return orderBy(availableMarkets, ['order'], ['desc']);
  }, [getMarketLanguages]);

  return {
    toggleModalShown,
    initMarketSelection,
    selectMarket,
    switcherMarkets,
  };
};

export { useMarketSwitcher };

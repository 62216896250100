import { create } from 'zustand';

enum MarketSwitcherStep {
  MarketSelection = 'market-selection',
  LanguageSelection = 'language-selection',
}

type Language = {
  label: string;
  slug: string;
  active: boolean;
};

type SwitcherMarket = {
  countryCode: string;
  countryCodeLong: string;
  name: string;
  languages: Language[];
  order: number;
};

type MarketSwitcherStore = {
  modalShown: boolean;
  step: MarketSwitcherStep;
  selectedMarket?: SwitcherMarket;
  actions: {
    toggleModalShown: (shown: boolean) => void;
    selectMarket: (market: SwitcherMarket) => void;
    changeStep: (step: MarketSwitcherStep) => void;
  };
};

const useMarketSwitcherStore = create<MarketSwitcherStore>()((set) => ({
  modalShown: false,
  step: MarketSwitcherStep.MarketSelection,
  actions: {
    toggleModalShown: (shown) => set({ modalShown: shown }),
    selectMarket: (market) =>
      set({
        selectedMarket: market,
        step: MarketSwitcherStep.LanguageSelection,
      }),
    changeStep: (step) => set({ step }),
  },
}));

const useModalShown = () => useMarketSwitcherStore((store) => store.modalShown);
const useStep = () => useMarketSwitcherStore((store) => store.step);
const useSelectedMarket = () => useMarketSwitcherStore((store) => store.selectedMarket);
const useMarketSwitcherActions = () => useMarketSwitcherStore((store) => store.actions);

export { useModalShown, useStep, useSelectedMarket, useMarketSwitcherActions, MarketSwitcherStep };
export type { SwitcherMarket, Language };

import { j as r } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as t } from "./chunks/clsx.0d472180.js";
import { SkeletonLoader as u } from "./skeletonLoader.mjs";
import { w as a } from "./chunks/styles.b9d17517.js";
import "react";
import "./chunks/useTranslatableUILabel.cc84b4da.js";
import "./chunks/TranslatableUIProvider.f5945ac7.js";
const l = ({
  loading: e = !1,
  loaderContent: s,
  className: o,
  loaderClassName: p,
  contentWrapperClassName: i,
  children: d,
  as: n = "div",
  contentWrapperAs: m = n,
  ...c
}) => /* @__PURE__ */ r.jsxs(n, { className: t(a.root, e && a.loading, o), children: [
  e && /* @__PURE__ */ r.jsx(u, { className: t(a.loader, p), ...c, children: s }),
  /* @__PURE__ */ r.jsx(
    m,
    {
      className: t(a.contentWrapper, i),
      "aria-hidden": e || void 0,
      children: d
    }
  )
] });
try {
  l.displayName = "SkeletonWrapper", l.__docgenInfo = { description: "", displayName: "SkeletonWrapper", props: { loading: { defaultValue: { value: !1 }, description: "", name: "loading", required: !1, type: { name: "boolean" } }, loaderContent: { defaultValue: null, description: "", name: "loaderContent", required: !1, type: { name: "ReactNode" } }, loaderClassName: { defaultValue: null, description: "", name: "loaderClassName", required: !1, type: { name: "string" } }, contentWrapperClassName: { defaultValue: null, description: "", name: "contentWrapperClassName", required: !1, type: { name: "string" } }, as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, contentWrapperAs: { defaultValue: null, description: "", name: "contentWrapperAs", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } } } };
} catch {
}
export {
  l as SkeletonWrapper
};

import { filter, toLower } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { MarketLanguage } from 'hooks/useMarket';
import { useMarketSwitcher } from './hooks/useMarketSwitcher';
import { MarketSearchInput } from './MarketSearchInput';
import { MarketSwitcherStepBase } from './MarketSwitcherStepBase';
import { MarketSelectionList } from './MarketSelectionList';

const matchesSearchQuery = (value: string | MarketLanguage, searchQuery: string) => {
  const marketValue = typeof value === 'string' ? value : value.slug;
  return toLower(marketValue).includes(toLower(searchQuery));
};

const MarketSelectionStep = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const { switcherMarkets } = useMarketSwitcher();
  const filteredMarkets = useMemo(
    () =>
      filter(switcherMarkets, ({ countryCode, countryCodeLong, languages, name }) =>
        [name, countryCode, countryCodeLong, ...languages].some((value) =>
          matchesSearchQuery(value, searchQuery),
        ),
      ),
    [searchQuery, switcherMarkets],
  );

  return (
    <MarketSwitcherStepBase
      title={t('marketSwitcher.countryTitle')}
      actions={
        <MarketSearchInput
          value={searchQuery}
          onChange={({ target: { value } }) => setSearchQuery(value)}
        />
      }
    >
      <MarketSelectionList markets={filteredMarkets} />
    </MarketSwitcherStepBase>
  );
};

export { MarketSelectionStep };

import { ModalTitle, Stack } from '@carvertical/ui';
import styles from './MarketSwitcherStepBase.module.scss';

type MarketSwitcherStepProps = React.PropsWithChildren<{
  title: string;
  actions?: React.ReactNode;
}>;

const MarketSwitcherStepBase = ({ actions, children, title }: MarketSwitcherStepProps) => (
  <div className={styles.root}>
    <Stack crossAxisAlign="stretch" gap={2} className={styles.header}>
      <ModalTitle className={styles.title}>{title}</ModalTitle>

      {actions}
    </Stack>

    {children}
  </div>
);

export { MarketSwitcherStepBase };
export type { MarketSwitcherStepProps };

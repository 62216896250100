import { Button, Stack } from '@carvertical/ui';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { getCountry } from 'utils/locale';
import { useRouteData } from 'context/RouteDataProvider';
import Flag from 'components/common/Flag';
import { getCountryName } from 'services/countries';
import { useMarketSwitcher } from './hooks/useMarketSwitcher';
import styles from './MarketSwitcherButton.module.scss';

type MarketSwitcherButtonProps = {
  className?: string;
};

const MarketSwitcherButton = ({ className }: MarketSwitcherButtonProps) => {
  const { toggleModalShown } = useMarketSwitcher();
  const { locale, market } = useRouteData();
  const { t } = useTranslation();

  return (
    <Button
      className={cx(styles.root, className)}
      size="m"
      variant="outlined"
      onClick={() => toggleModalShown(true)}
      aria-label={`${t('marketSwitcher.changeLabel')} ${getCountryName(market.countryCode)}`}
    >
      <Stack as="span" type="horizontal" crossAxisAlign="center" gap={1}>
        <Flag
          as={undefined}
          aria-hidden="true"
          countryCode={market.countryCode}
          size={24}
          rounded
          className={styles.flag}
        />

        {locale.length > 2 ? locale : getCountry(locale)}
      </Stack>
    </Button>
  );
};

export { MarketSwitcherButton };

import { j as t } from "./chunks/jsx-runtime.82a9ddf6.js";
import { $ as h } from "./chunks/index.69d44069.js";
import { b as v } from "./chunks/TagGroup.b57eb2f5.js";
import { useContext as y } from "react";
import { c as l } from "./chunks/cn.2ea4f521.js";
import { Icon as i } from "./icon.mjs";
import { a as N } from "./chunks/TagGroupContext.b8226d23.js";
import "./chunks/utils.f8c6b750.js";
import "./chunks/clsx.0d472180.js";
import "react-dom";
import "./chunks/useFocusable.f923b0dc.js";
import "./chunks/useListState.980882c9.js";
import "./chunks/styles.b9d17517.js";
const c = ({
  children: a,
  size: d,
  variant: u,
  leadingAddon: p,
  status: o,
  className: m,
  ...g
}) => {
  const { size: b, variant: x } = y(N), e = d || b, r = u || x, f = typeof a == "string" ? a : void 0, n = o === "error" ? /* @__PURE__ */ t.jsx(i, { name: "attention-filled", size: "s", className: "text-red" }) : p;
  return /* @__PURE__ */ t.jsx(
    v,
    {
      textValue: f,
      ...g,
      className: l(
        "twp inline-flex items-center rounded-pill text-center outline outline-1 transition ease-out selection-single:cursor-pointer selection-multiple:cursor-pointer focus-visible:ring pressed:scale-95",
        {
          "px-1 py-0.75": e === "l",
          "px-0.75 py-0.5": e === "m"
        },
        {
          "bg-white text-black outline-transparent hover:bg-grey-200 hover:outline-grey-200 selected:bg-blue selected:text-white selected:outline-blue": r === "white",
          "bg-white text-black outline-grey-200 hover:bg-grey-200 hover:outline-grey-200 selected:bg-blue selected:text-white selected:outline-blue": r === "outline",
          "bg-blue-400 text-white outline-blue-400 hover:bg-blue hover:outline-blue selected:bg-darkBlue selected:text-white selected:outline-darkBlue": r === "blue",
          "bg-grey-800 text-white outline-transparent hover:bg-grey-600 hover:outline-grey-600 selected:bg-blue selected:outline-blue": r === "black",
          "bg-red-100 text-black outline-red-300": o === "error"
        },
        m
      ),
      children: ({ allowsRemoving: s }) => /* @__PURE__ */ t.jsxs(
        "div",
        {
          className: l("flex items-center gap-0.5", {
            "text-s": e === "l",
            "text-xs": e === "m"
          }),
          children: [
            n,
            /* @__PURE__ */ t.jsx(
              "div",
              {
                className: l(
                  {
                    "[--spacing:2px]": e === "m",
                    "[--spacing:4px]": e === "l"
                  },
                  {
                    "pr-[--spacing]": !s,
                    "pl-[--spacing]": !n
                  }
                ),
                children: a
              }
            ),
            s && /* @__PURE__ */ t.jsxs(
              h,
              {
                slot: "remove",
                className: "group relative isolate translate-x-[1px] outline-none",
                children: [
                  /* @__PURE__ */ t.jsx(
                    "div",
                    {
                      className: l(
                        "absolute inset-0 -z-10 rounded-full bg-transparent transition-colors group-hover:bg-black/10",
                        {
                          "group-hover:bg-white/20": r === "black",
                          "group-hover:bg-red/10": o === "error"
                        },
                        {
                          "scale-125": e === "m",
                          "scale-150": e === "l"
                        }
                      )
                    }
                  ),
                  /* @__PURE__ */ t.jsx(i, { name: "close", size: "s" })
                ]
              }
            )
          ]
        }
      )
    }
  );
};
try {
  c.displayName = "Tag", c.__docgenInfo = { description: "", displayName: "Tag", props: { size: { defaultValue: null, description: "", name: "size", required: !1, type: { name: "TagSize" } }, className: { defaultValue: null, description: "The CSS [className](https://developer.mozilla.org/en-US/docs/Web/API/Element/className) for the element. A function may be provided to compute the class based on component state.", name: "className", required: !1, type: { name: "string | (string & ((values: TagRenderProps & { defaultClassName: string; }) => string))" } }, status: { defaultValue: null, description: "", name: "status", required: !1, type: { name: '"error"' } }, variant: { defaultValue: null, description: "", name: "variant", required: !1, type: { name: "TagVariant" } }, leadingAddon: { defaultValue: null, description: "", name: "leadingAddon", required: !1, type: { name: "ReactNode" } }, children: { defaultValue: null, description: "The children of the component. A function may be provided to alter the children based on component state.", name: "children", required: !1, type: { name: "ReactNode & (ReactNode | ((values: TagRenderProps & { defaultChildren: ReactNode; }) => ReactNode))" } } } };
} catch {
}
export {
  c as Tag
};

import { ExternalLink, Text } from '@carvertical/ui';
import { Link } from 'components/common/Link';
import type { GroupPage } from './types';
import styles from './FooterLink.module.scss';

type LinkComponent = typeof Link | typeof ExternalLink | 'a';

type FooterLinkProps = {
  pageId: string;
  pageInfo: GroupPage;
  label?: string;
  url?: string;
  className?: string;
};

const EXTERNAL_PAGE_IDS = ['informationSecurityPolicy'];

const FooterLink = ({
  className,
  label: labelFromProps,
  pageId,
  pageInfo,
  url,
}: FooterLinkProps) => {
  let label = labelFromProps;

  if (!label) {
    label = pageInfo?.title;
  }

  let LinkComponent: LinkComponent = url ? ExternalLink : Link;
  if (pageId && EXTERNAL_PAGE_IDS.includes(pageId)) {
    LinkComponent = 'a';
  }

  return (
    <Text variant="s" className={className}>
      <LinkComponent href={url || pageInfo.path} className={styles.innerLink}>
        {label}
      </LinkComponent>
    </Text>
  );
};

export { FooterLink };
export type { FooterLinkProps };

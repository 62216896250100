import { forwardRef, type ReactNode, useRef } from 'react';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Loader from 'components/common/Loader';
import { Icon } from 'components/common/Icon';
import MailIcon from 'assets/icons/mail.svg';
import YesIcon from 'assets/icons/check-yes.svg';
import NoIcon from 'assets/icons/check-no.svg';
import type { InputProps } from 'types/component';
import { Button, type ButtonProps } from '../Button';
import styles from './TextInput.module.scss';

type Color = 'transparent' | 'light' | 'grey';

type TextInputProps = {
  label: string;
  buttonProps?: {
    label: ReactNode | string;
  } & ButtonProps;
  error?: boolean;
  labelHidden?: boolean;
  helperText?: string;
  leftAddon?: ReactNode;
  rightAddon?: ReactNode;
  state?: 'success' | 'failure' | 'pending';
  fullWidth?: boolean;
  color?: Color;
  stateText?: string | JSX.Element;
  size?: 'medium' | 'large';
  inputWrapperClassName?: string;
  emphasized?: boolean;
  onReset?: () => void;
  placedOnDarkBackground?: boolean;
} & Omit<InputProps, 'size' | 'onReset'>;

const STATE_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, height: 0 },
  visible: { opacity: 1, height: 'auto' },
};

const PREFIX_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1 },
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      buttonProps,
      className,
      color = 'transparent',
      disabled = false,
      emphasized = false,
      error = false,
      fullWidth = false,
      helperText,
      id,
      inputWrapperClassName = undefined,
      label,
      labelHidden = false,
      leftAddon: leftAddonFromProps,
      onReset = undefined,
      placedOnDarkBackground = false,
      placeholder,
      readOnly = false,
      rightAddon,
      size = 'medium',
      state,
      stateText,
      type = 'text',
      ...otherProps
    },
    ref,
  ) => {
    const inputWrapperRef = useRef<HTMLDivElement>(null);

    let leftAddon = leftAddonFromProps;

    const { label: buttonLabel, variant: buttonVariant, ...buttonOtherProps } = buttonProps || {};

    if (!leftAddon && type === 'email') {
      leftAddon = <MailIcon className={styles.icon} original />;
    }

    const focusInput = () => {
      inputWrapperRef.current?.querySelector('input')?.focus();
    };

    const valueSet = !!inputWrapperRef.current?.querySelector('input')?.value;
    const clearButtonVisible = onReset && valueSet;

    return (
      <div
        className={cx(
          styles.root,
          fullWidth && styles.fullWidth,
          placedOnDarkBackground && styles.white,
          className,
        )}
      >
        <label className={cx(styles.label, labelHidden && styles.hidden)} htmlFor={id}>
          {label}
        </label>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div
          className={cx(
            styles.inputWrapper,
            styles[size],
            styles[color],
            state && styles[state],
            disabled && styles.disabled,
            error && styles.error,
            readOnly && styles.readOnly,
            inputWrapperClassName,
          )}
          ref={inputWrapperRef}
          onClick={focusInput}
        >
          <AnimatePresence mode="wait">
            {state && (
              <motion.div
                key={state}
                variants={PREFIX_ANIMATION_VARIANTS}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className={styles.addonWrapper}
              >
                {state === 'pending' && <Loader className={styles.loader} />}

                {state === 'success' && (
                  <YesIcon className={cx(styles.stateIcon, styles.success)} />
                )}

                {state === 'failure' && <NoIcon className={cx(styles.stateIcon, styles.failure)} />}
              </motion.div>
            )}
          </AnimatePresence>

          {leftAddon && <div className={styles.addonWrapper}>{leftAddon}</div>}

          <input
            id={id}
            ref={ref}
            type={type}
            disabled={disabled}
            className={cx(
              styles.input,
              emphasized && styles.emphasized,
              !!onReset && styles.clearable,
            )}
            placeholder={placeholder}
            readOnly={readOnly}
            {...otherProps}
          />

          {rightAddon && <div className={styles.addonWrapper}>{rightAddon}</div>}

          {onReset && (
            <Button
              onClick={onReset}
              className={cx(styles.clearButton, clearButtonVisible && styles.visible)}
              aria-label="Clear input"
            >
              <Icon name="x" size="s" />
            </Button>
          )}

          {buttonProps && (
            <Button
              variant={buttonVariant || 'dark'}
              size={size === 'large' ? 'medium' : 'small'}
              className={styles.button}
              disabled={disabled}
              {...buttonOtherProps}
            >
              {buttonProps.label}
            </Button>
          )}
        </div>
        <AnimatePresence>
          {state && stateText && (
            <motion.div
              variants={STATE_ANIMATION_VARIANTS}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              {state === 'failure' && (
                <p className={cx(styles.stateText, styles.failure)}>{stateText}</p>
              )}

              {state === 'success' && (
                <p className={cx(styles.stateText, styles.success)}>{stateText}</p>
              )}
            </motion.div>
          )}
        </AnimatePresence>
        {helperText && <p className={styles.helperText}>{helperText}</p>}
      </div>
    );
  },
);

export default TextInput;
export type { TextInputProps };

import { $ as R, a as V, b as E, c as N, d as A, e as O, f as k, g as z, h as w, i as B, j } from "./useListState.980882c9.js";
import { e as v, g as H, a as y, m as U, n as W, i as q, o as G, k as x, l as h, p as J, q as Q, r as P, j as X } from "./utils.f8c6b750.js";
import u, { useMemo as K, useRef as Y, useEffect as Z, forwardRef as m, useContext as S, createContext as M } from "react";
const L = /* @__PURE__ */ new WeakMap();
function p(t, e, a) {
  return typeof e == "string" && (e = e.replace(/\s+/g, "")), `${L.get(t)}-${a}-${e}`;
}
function ee(t, e, a) {
  let { key: i, isDisabled: l, shouldSelectOnPressUp: s } = t, { selectionManager: n, selectedKey: d } = e, o = i === d, r = l || e.isDisabled || e.selectionManager.isDisabled(i), { itemProps: c, isPressed: f } = R({
    selectionManager: n,
    key: i,
    ref: a,
    isDisabled: r,
    shouldSelectOnPressUp: s,
    linkBehavior: "selection"
  }), $ = p(e, i, "tab"), g = p(e, i, "tabpanel"), { tabIndex: T } = c, b = e.collection.getItem(i), I = v(b == null ? void 0 : b.props, {
    labelable: !0
  });
  delete I.id;
  let C = H(b == null ? void 0 : b.props);
  return {
    tabProps: y(I, C, c, {
      id: $,
      "aria-selected": o,
      "aria-disabled": r || void 0,
      "aria-controls": o ? g : void 0,
      tabIndex: r ? void 0 : T,
      role: "tab"
    }),
    isSelected: o,
    isDisabled: r,
    isPressed: f
  };
}
function te(t, e, a) {
  let i = V(a) ? void 0 : 0;
  var l;
  const s = p(e, (l = t.id) !== null && l !== void 0 ? l : e == null ? void 0 : e.selectedKey, "tabpanel"), n = E({
    ...t,
    id: s,
    "aria-labelledby": p(e, e == null ? void 0 : e.selectedKey, "tab")
  });
  return {
    tabPanelProps: y(n, {
      tabIndex: i,
      role: "tabpanel",
      "aria-describedby": t["aria-describedby"],
      "aria-details": t["aria-details"]
    })
  };
}
class ae {
  getKeyLeftOf(e) {
    return this.flipDirection ? this.getNextKey(e) : this.getPreviousKey(e);
  }
  getKeyRightOf(e) {
    return this.flipDirection ? this.getPreviousKey(e) : this.getNextKey(e);
  }
  getKeyAbove(e) {
    return this.getPreviousKey(e);
  }
  getKeyBelow(e) {
    return this.getNextKey(e);
  }
  isDisabled(e) {
    var a, i;
    return this.disabledKeys.has(e) || !!(!((i = this.collection.getItem(e)) === null || i === void 0 || (a = i.props) === null || a === void 0) && a.isDisabled);
  }
  getFirstKey() {
    let e = this.collection.getFirstKey();
    return e != null && this.isDisabled(e) && (e = this.getNextKey(e)), e;
  }
  getLastKey() {
    let e = this.collection.getLastKey();
    return e != null && this.isDisabled(e) && (e = this.getPreviousKey(e)), e;
  }
  getNextKey(e) {
    do
      e = this.collection.getKeyAfter(e), e == null && (e = this.collection.getFirstKey());
    while (this.isDisabled(e));
    return e;
  }
  getPreviousKey(e) {
    do
      e = this.collection.getKeyBefore(e), e == null && (e = this.collection.getLastKey());
    while (this.isDisabled(e));
    return e;
  }
  constructor(e, a, i, l = /* @__PURE__ */ new Set()) {
    this.collection = e, this.flipDirection = a === "rtl" && i === "horizontal", this.disabledKeys = l;
  }
}
function ie(t, e, a) {
  let { orientation: i = "horizontal", keyboardActivation: l = "automatic" } = t, { collection: s, selectionManager: n, disabledKeys: d } = e, { direction: o } = N(), r = K(() => new ae(s, o, i, d), [
    s,
    d,
    i,
    o
  ]), { collectionProps: c } = A({
    ref: a,
    selectionManager: n,
    keyboardDelegate: r,
    selectOnFocus: l === "automatic",
    disallowEmptySelection: !0,
    scrollRef: a,
    linkBehavior: "selection"
  }), f = U();
  L.set(e, f);
  let $ = E({
    ...t,
    id: f
  });
  return {
    tabListProps: {
      ...y(c, $),
      role: "tablist",
      "aria-orientation": i,
      tabIndex: void 0
    }
  };
}
function le(t) {
  var e;
  let [a, i] = O(t.selectedKey, (e = t.defaultSelectedKey) !== null && e !== void 0 ? e : null, t.onSelectionChange), l = K(() => a != null ? [
    a
  ] : [], [
    a
  ]), { collection: s, disabledKeys: n, selectionManager: d } = k({
    ...t,
    selectionMode: "single",
    disallowEmptySelection: !0,
    allowDuplicateSelectionEvents: !0,
    selectedKeys: l,
    onSelectionChange: (r) => {
      var c;
      let f = (c = r.values().next().value) !== null && c !== void 0 ? c : null;
      f === a && t.onSelectionChange && t.onSelectionChange(f), i(f);
    }
  }), o = a != null ? s.getItem(a) : null;
  return {
    collection: s,
    disabledKeys: n,
    selectionManager: d,
    selectedKey: a,
    setSelectedKey: i,
    selectedItem: o
  };
}
function se(t) {
  var e;
  let a = le({
    ...t,
    suppressTextValueWarning: !0,
    defaultSelectedKey: (e = t.defaultSelectedKey) !== null && e !== void 0 ? e : _(t.collection, t.disabledKeys ? new Set(t.disabledKeys) : /* @__PURE__ */ new Set())
  }), { selectionManager: i, collection: l, selectedKey: s } = a, n = Y(s);
  return Z(() => {
    let d = s;
    (i.isEmpty || !l.getItem(d)) && (d = _(l, a.disabledKeys), d != null && i.setSelectedKeys([
      d
    ])), (d != null && i.focusedKey == null || !i.isFocused && d !== n.current) && i.setFocusedKey(d), n.current = d;
  }), {
    ...a,
    isDisabled: t.isDisabled || !1
  };
}
function _(t, e) {
  let a = null;
  if (t) {
    for (a = t.getFirstKey(); e.has(a) && a !== t.getLastKey(); )
      a = t.getKeyAfter(a);
    e.has(a) && a === t.getLastKey() && (a = t.getFirstKey());
  }
  return a;
}
const D = /* @__PURE__ */ M(null), F = /* @__PURE__ */ M(null);
function de(t, e) {
  [t, e] = q(t, e, D);
  let { collection: a, document: i } = z(), { children: l, orientation: s = "horizontal" } = t;
  return l = K(() => typeof l == "function" ? l({
    orientation: s,
    defaultChildren: null
  }) : l, [
    l,
    s
  ]), /* @__PURE__ */ u.createElement(u.Fragment, null, /* @__PURE__ */ u.createElement(G, null, /* @__PURE__ */ u.createElement(w.Provider, {
    value: i
  }, l)), /* @__PURE__ */ u.createElement(ne, {
    props: t,
    collection: a,
    tabsRef: e
  }));
}
function ne({ props: t, tabsRef: e, collection: a }) {
  let { orientation: i = "horizontal" } = t, l = se({
    ...t,
    collection: a,
    children: void 0
  }), { focusProps: s, isFocused: n, isFocusVisible: d } = x({
    within: !0
  }), o = K(() => ({
    orientation: i,
    isFocusWithin: n,
    isFocusVisible: d
  }), [
    i,
    n,
    d
  ]), r = h({
    ...t,
    defaultClassName: "react-aria-Tabs",
    values: o
  });
  return /* @__PURE__ */ u.createElement("div", {
    ...v(t),
    ...s,
    ...r,
    ref: e,
    slot: t.slot || void 0,
    "data-focused": n || void 0,
    "data-orientation": i,
    "data-focus-visible": d || void 0,
    "data-disabled": l.isDisabled || void 0
  }, /* @__PURE__ */ u.createElement(J, {
    values: [
      [
        D,
        t
      ],
      [
        F,
        l
      ]
    ]
  }, r.children));
}
const pe = /* @__PURE__ */ m(de);
function oe(t, e) {
  return S(w) ? /* @__PURE__ */ u.createElement(B, t) : /* @__PURE__ */ u.createElement(re, {
    props: t,
    forwardedRef: e
  });
}
function re({ props: t, forwardedRef: e }) {
  let a = S(F), { orientation: i = "horizontal", keyboardActivation: l = "automatic" } = Q(D), s = P(e), { tabListProps: n } = ie({
    ...t,
    orientation: i,
    keyboardActivation: l
  }, a, s), d = h({
    ...t,
    children: null,
    defaultClassName: "react-aria-TabList",
    values: {
      orientation: i,
      state: a
    }
  }), o = v(t);
  return delete o.id, /* @__PURE__ */ u.createElement("div", {
    ...o,
    ...n,
    ref: s,
    ...d,
    "data-orientation": i || void 0
  }, [
    ...a.collection
  ].map((r) => /* @__PURE__ */ u.createElement(fe, {
    key: r.key,
    item: r,
    state: a
  })));
}
const ve = /* @__PURE__ */ m(oe);
function ce(t, e) {
  return j("item", t, e, t.children);
}
const he = /* @__PURE__ */ m(ce);
function fe({ item: t, state: e }) {
  let a = P(t.props.ref), { tabProps: i, isSelected: l, isDisabled: s, isPressed: n } = ee({
    key: t.key,
    ...t.props
  }, e, a), { focusProps: d, isFocused: o, isFocusVisible: r } = x(), { hoverProps: c, isHovered: f } = X({
    isDisabled: s
  }), $ = h({
    ...t.props,
    children: t.rendered,
    defaultClassName: "react-aria-Tab",
    values: {
      isSelected: l,
      isDisabled: s,
      isFocused: o,
      isFocusVisible: r,
      isPressed: n,
      isHovered: f,
      state: e
    }
  }), g = t.props.href ? "a" : "div";
  return /* @__PURE__ */ u.createElement(g, {
    ...y(i, d, c, $),
    ref: a,
    "data-selected": l || void 0,
    "data-disabled": s || void 0,
    "data-focused": o || void 0,
    "data-focus-visible": r || void 0,
    "data-pressed": n || void 0,
    "data-hovered": f || void 0
  });
}
function ue(t, e) {
  const a = S(F);
  let i = P(e), { tabPanelProps: l } = te(t, a, i), { focusProps: s, isFocused: n, isFocusVisible: d } = x(), o = a.selectedKey === t.id, r = h({
    ...t,
    defaultClassName: "react-aria-TabPanel",
    values: {
      isFocused: n,
      isFocusVisible: d,
      isInert: !o,
      state: a
    }
  });
  if (!o && !t.shouldForceMount)
    return null;
  let c = v(t);
  delete c.id;
  let f = o ? y(c, l, s, r) : r;
  return /* @__PURE__ */ u.createElement("div", {
    ...f,
    ref: i,
    "data-focused": n || void 0,
    "data-focus-visible": d || void 0,
    // @ts-ignore
    inert: o ? void 0 : "true",
    "data-inert": o ? void 0 : "true"
  });
}
const Ke = /* @__PURE__ */ W(ue);
export {
  pe as $,
  ve as a,
  he as b,
  Ke as c
};

import { Badge, Button, Container, Stack, theme } from '@carvertical/ui';
import { useMedia } from 'react-use';
import { SupportEmail } from 'modules/support/components';
import { useRouteData } from 'context/RouteDataProvider';
import { createMediaQuery } from 'utils/styles';
import { MarketSwitcherModal } from 'components/common/MarketSwitcher/MarketSwitcherModal';
import withContainers from 'hoc/withContainers';
import AuthContainer from 'containers/AuthContainer';
import { useModal } from '../../hooks';
import Logo from './Logo';
import { VerticalMenu } from './VerticalMenu';
import { PolicyNotice } from './PolicyNotice';
import { HorizontalMenu } from './HorizontalMenu';
import { CreditLoginBar } from './CreditLoginBar';
import { UserMenuDialog } from './UserMenuDialog';
import styles from './Header.module.scss';

type HeaderProps = {
  supportEmailShown?: boolean;
  creditLoginBarShown?: boolean;
  navigationShown?: boolean;
  logoInteractive?: boolean;
  logoSuffix?: string;
  logoOnly?: boolean;
  containers: {
    auth: AuthContainer;
  };
};

const Header = ({
  logoSuffix,
  navigationShown = true,
  supportEmailShown = false,
  creditLoginBarShown = false,
  logoInteractive = true,
  logoOnly,
  containers: { auth },
}: HeaderProps) => {
  const { market } = useRouteData();
  const impersonating = auth.isImpersonating();
  const { authenticated } = auth.state;
  const { marketSwitcherDisabled } = market;
  const { close: closeAccountDialog } = useModal('account');

  const isMobileBreakpoint = useMedia(createMediaQuery(theme.breakpointTabletPortrait, false));

  /* TODO: Replace with @carvertical/ui `Logo` component once ready */
  const renderLogo = () => (
    <Logo
      aria-label="carVertical"
      suffix={logoSuffix}
      to={logoInteractive ? '/' : null}
      priority
      className={styles.logo}
      width={isMobileBreakpoint ? 150 : 180}
    />
  );

  return (
    <>
      {impersonating && (
        <Badge variant="red" size="s" className={styles.impersonationBanner} emphasized>
          {`You're impersonating!`}
        </Badge>
      )}

      <header className={styles.root}>
        <Container className={styles.container}>
          {logoOnly ? (
            renderLogo()
          ) : (
            <>
              {authenticated && <UserMenuDialog />}

              {renderLogo()}

              {navigationShown && (
                <>
                  <HorizontalMenu marketSwitcherShown={!marketSwitcherDisabled} />

                  <VerticalMenu
                    marketSwitcherShown={!marketSwitcherDisabled}
                    onOpen={closeAccountDialog}
                  />
                </>
              )}

              {(creditLoginBarShown || supportEmailShown) && (
                <Stack gap={1.5} type="horizontal" crossAxisAlign="center">
                  {creditLoginBarShown && <CreditLoginBar />}

                  {supportEmailShown && (
                    <SupportEmail
                      as={Button}
                      variant="blue"
                      size={isMobileBreakpoint ? 's' : 'm'}
                      className={styles.contactButton}
                    />
                  )}
                </Stack>
              )}
            </>
          )}
        </Container>

        {!marketSwitcherDisabled && <MarketSwitcherModal />}
      </header>

      <PolicyNotice />
    </>
  );
};

const WrappedHeader = withContainers({ auth: AuthContainer })(Header);

export { WrappedHeader as Header };
export type { HeaderProps };

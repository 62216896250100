import { type ModalId, useModalActions, useIsModalOpen } from './useModalStore';

const useModal = (id: ModalId) => {
  const { openModal, closeModal } = useModalActions();
  const isModalOpen = useIsModalOpen(id);

  const open = () => openModal(id);
  const close = () => closeModal(id);

  return {
    open,
    close,
    isModalOpen,
  };
};

export { useModal };

import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { trustBadgeUrl } from 'config';
import { UserType } from 'types/user';
import { isExistingMarket } from 'utils/market';
import { useRouteData } from 'context/RouteDataProvider';
import withContainers from 'hoc/withContainers';
import UserContainer from 'containers/UserContainer';
import AuthContainer from 'containers/AuthContainer';
import { RefillButton } from 'components/common/RefillButton';
import { UsageCounter } from 'components/common/UsageCounter';
import { useHeaderMenu } from 'modules/shared/components/header/hooks';
import { TRUST_BADGE_LABEL, TRUST_BADGE_MARKETS } from 'modules/shared/components/header/constants';
import { Menu } from 'modules/shared/components/header/Menu';
import { useModal } from 'modules/shared/hooks';
import { UserHeader } from './UserHeader';

type UserMenuProps = {
  containers: {
    auth: AuthContainer;
    user: UserContainer;
  };
  className?: string;
  variant?: 's' | 'm';
};

const UserMenu = ({ containers: { auth, user }, className, variant }: UserMenuProps) => {
  const { t } = useTranslation();
  const { market, locale } = useRouteData();
  const [togglingImpersonation, setTogglingImpersonation] = useState(false);
  const { flagActiveItems, filterItems } = useHeaderMenu();
  const { open: openLogoutModal } = useModal('logout');

  const impersonate = async () => {
    if (auth.isImpersonating()) {
      setTogglingImpersonation(true);
      await auth.unimpersonate();
    } else {
      const userId = window.prompt('Enter user ID:'); // eslint-disable-line no-alert
      if (!userId) {
        return;
      }
      setTogglingImpersonation(true);
      await auth.impersonate(userId);
    }
    window.location.reload();
  };

  const impersonating = auth.isImpersonating();
  const trustBadgeLocalized = isExistingMarket({
    locale,
    market,
    markets: TRUST_BADGE_MARKETS,
  });

  const items = flagActiveItems(
    filterItems([
      { title: t('reportsPage.title'), path: '/user/reports' },
      { title: t('settingsPage.title'), path: '/user/settings', hasSubItems: true },
      { title: t('transactionsPage.title'), path: '/user/transactions', hasSubItems: true },
      { title: 'API', path: '/user/api' },
      {
        title: TRUST_BADGE_LABEL,
        href: `${trustBadgeUrl}/${trustBadgeLocalized ? locale : ''}`,
        included: user.userType === UserType.Company,
        blank: true,
      },
      {
        title: impersonating ? 'Stop impersonating' : 'Impersonate',
        onClick: impersonate,
        disabled: togglingImpersonation,
        included: user.level === 'admin' || impersonating,
      },
      { title: t('auth.logOutAction'), onClick: openLogoutModal, separate: true, emphasized: true },
    ]),
  );

  return (
    <Menu
      className={cx('px-2', className)}
      items={items}
      variant={variant}
      header={<UserHeader />}
      subHeader={
        <div className="flex flex-col gap-1.5">
          <UsageCounter quota={user.creditCount} />

          <RefillButton
            userType={user.userType}
            buttonProps={{
              fullWidth: true,
              className: 'text-center px-4 py-1.5 md:px-1.5 md:py-1',
            }}
          />
        </div>
      }
    />
  );
};

const WrappedUserMenu = withContainers({ auth: AuthContainer, user: UserContainer })(UserMenu);

export { WrappedUserMenu as UserMenu };
export type { UserMenuProps };

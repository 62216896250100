import { motion } from 'framer-motion';
import { Icon, Text, Stack, AriaButton } from '@carvertical/ui';
import { forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import type { MenuGroup } from '../types';
import {
  DIVIDER_ANIMATION_PROPS,
  ITEMS_WRAPPER_ANIMATION_PROPS,
  ITEM_ANIMATION_PROPS,
} from './animation';
import { Item } from './Item';
import styles from './SecondaryNavigation.module.scss';

type SecondaryNavigationProps = {
  group: MenuGroup;
  onBack?: () => void;
  className?: string;
};

const SecondaryNavigation = forwardRef<HTMLDivElement, SecondaryNavigationProps>(
  ({ group, onBack, className }, ref) => {
    const { t } = useTranslation();

    const { title, items, Footer } = group;

    return (
      <motion.nav {...ITEMS_WRAPPER_ANIMATION_PROPS} ref={ref} className={className}>
        <motion.div className={styles.header} {...ITEM_ANIMATION_PROPS} custom={{ reverse: true }}>
          <AriaButton className={styles.backButton} onPress={onBack}>
            <Stack gap={0} type="horizontal" crossAxisAlign="center">
              <Icon name="chevron-left-bold" size="m" />

              {t('general.backAction')}
            </Stack>
          </AriaButton>

          <motion.h2 className={styles.title}>
            <Text variant="s">{title}</Text>
          </motion.h2>
        </motion.div>

        <motion.div className={styles.divider} {...DIVIDER_ANIMATION_PROPS} />

        <motion.ul className="unstyledList">
          {items.map(({ title: itemTitle, path, active }) => (
            <motion.li {...ITEM_ANIMATION_PROPS} custom={{ reverse: true }} key={path}>
              <Item to={path} active={active}>
                {itemTitle}
              </Item>
            </motion.li>
          ))}
        </motion.ul>

        {Footer && (
          <>
            <motion.div className={styles.divider} {...DIVIDER_ANIMATION_PROPS} />

            <motion.div
              {...ITEM_ANIMATION_PROPS}
              custom={{ reverse: true }}
              className={styles.footer}
            >
              <Footer />
            </motion.div>
          </>
        )}
      </motion.nav>
    );
  },
);

export { SecondaryNavigation };
export type { SecondaryNavigationProps };

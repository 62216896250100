import { Stack } from '@carvertical/ui';
import { social } from 'config';
import ExternalLink from 'components/common/ExternalLink';
import FacebookIcon from 'assets/icons/facebook.svg';
import InstagramIcon from 'assets/icons/instagram.svg';
import YoutubeIcon from 'assets/icons/social/youtube-play.svg';
import type { SVGComponent } from 'types/component';
import styles from './SocialLinks.module.scss';

type Link = {
  to: string;
  label: string;
  Icon: SVGComponent;
};

const LINKS: Link[] = [
  {
    to: social.youTube,
    label: 'YouTube',
    Icon: YoutubeIcon,
  },
  {
    to: social.facebook,
    label: 'Facebook',
    Icon: FacebookIcon,
  },
  {
    to: social.instagram,
    label: 'Instagram',
    Icon: InstagramIcon,
  },
];

const SocialLinks = () => (
  <Stack gap={1.5} as="ul" type="horizontal" className="unstyledList">
    {LINKS.map(({ Icon, label, to }) => (
      <li key={label}>
        <ExternalLink href={to} title={label} className={styles.item}>
          <Icon className={styles.icon} />
        </ExternalLink>
      </li>
    ))}
  </Stack>
);

export { SocialLinks };

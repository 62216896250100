import { Stack } from '@carvertical/ui';
import { AnimatePresence, motion, type AnimationProps } from 'framer-motion';
import { useId } from 'react';
import { MarketSwitcherStep, useStep } from './hooks/useMarketSwitcherStore';
import { LanguageSelectionStep } from './LanguageSelectionStep';
import { MarketSelectionStep } from './MarketSelectionStep';

const ANIMATION_PROPS: AnimationProps = {
  initial: 'hidden',
  animate: 'visible',
  exit: 'exit',
  transition: {
    x: {
      type: 'spring',
      stiffness: 180,
      damping: 25,
    },
    ease: 'easeInOut',
    duration: 0.2,
  },
  variants: {
    hidden: (step: MarketSwitcherStep) => ({
      x: step === MarketSwitcherStep.LanguageSelection ? '100%' : '-100%',
      opacity: 0,
    }),
    visible: {
      x: 0,
      opacity: 1,
    },
    exit: (step: MarketSwitcherStep) => ({
      x: step === MarketSwitcherStep.MarketSelection ? '100%' : '-100%',
      opacity: 0,
    }),
  },
};

const MarketSwitcherBody = () => {
  const step = useStep();
  const marketSelectionId = useId();
  const languageSelectionId = useId();

  return (
    <AnimatePresence initial={false} mode="popLayout" custom={step}>
      {step === MarketSwitcherStep.MarketSelection && (
        <Stack
          as={motion.div}
          key={marketSelectionId}
          // @ts-expect-error TS(2322)
          custom={step}
          {...ANIMATION_PROPS}
        >
          <MarketSelectionStep />
        </Stack>
      )}

      {step === MarketSwitcherStep.LanguageSelection && (
        <Stack
          as={motion.div}
          key={languageSelectionId}
          // @ts-expect-error TS(2322)
          custom={step}
          {...ANIMATION_PROPS}
        >
          <LanguageSelectionStep />
        </Stack>
      )}
    </AnimatePresence>
  );
};

export { MarketSwitcherBody };

import { AriaButton } from '@carvertical/ui';
import cx from 'classnames';
import { Link } from 'components/common/Link';
import styles from './Item.module.scss';

type ItemProps = {
  onClick?: () => void;
  children?: React.ReactNode;
  to?: string;
  active?: boolean;
};

const Item = ({ onClick, children, to, active }: ItemProps) => {
  const RenderAs = to ? Link : AriaButton;

  return (
    <RenderAs
      className={cx(styles.root, active && styles.active)}
      onClick={onClick}
      to={to}
      tabIndex={active ? -1 : 0}
    >
      {children}
    </RenderAs>
  );
};

export { Item };
export type { ItemProps };
